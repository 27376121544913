import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal"

const Hero = (props) => {
  // console.log("props", props)
  const image = props.heroImage ? props.heroImage.localFile : null
  const pathToImage = getImage(image)
  const title = props.title

  return (
    <Fade triggerOnce duration={500}>
      <section className="relative c-hero bg-grey-75">
        <div className="absolute w-full h-full overflow-hidden">

        { image && <GatsbyImage className="w-full h-full" image={pathToImage} alt={title} />}

        </div>
        <div className="container-fluid h-200 md:h-280 lg:h-420">
          <div className="absolute z-10 w-11/12 text-white transform -translate-y-1/2 md:w-8/12 lg:w-6/12 top-1/2 ">
            <div className="lg:mt-6 hero">{title}</div>
          </div>
        </div>
      </section>
    </Fade>
  )
}

export default Hero
