import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import SliceZone from "../../components/SliceZone"
import HeroArticle from "../../components/HeroArticle"

const isBrowser = typeof window !== "undefined"

function ArticlePage({ location, data }) {

  if (isBrowser) {
    
    if (!data) return null
    
    const url = location.href ? location.href : '';
    const { type, uid } = data.allPrismicArticle.edges[0].node
    const seo = data.allPrismicArticle.edges[0].node.data.body[0].primary
    const document = data.allPrismicArticle.edges[0].node.data
    const title = document.article_title
    const intro = document.article_intro.html

    return (
      <div>
        <Layout pageType={type} uid={uid}>
          <Seo title={title} seo={seo} url={url} />
          <HeroArticle title={title} heroImage={document.article_hero_image}/>

          <section className="my-6 md:mt-12 md:mb-6">
            <div className="mx-auto lg:max-w-4xl">
              <div className="container-fluid">
                <div className="simple-text">
                  <div
                    className="font-bold text-heading-4-lg"
                    dangerouslySetInnerHTML={{
                      __html: intro,
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          
          <SliceZone sliceZone={document.body1} />
          
        </Layout>
      </div>
    )
  }

  return <div> </div>
}

export const query = graphql`
  query ArticlePageQuery($uid: String) {
    allPrismicArticle(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          type
          url
          data {
            article_title
            article_hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            article_intro {
              html
            }
            body1 {
              ... on PrismicArticleDataBody1Accordion {
                slice_type
                items {
                  accordion_item_title
                  accordion_item_copy {
                    html
                  }
                }
              }
              ... on PrismicArticleDataBody1RichText {
                slice_type
                primary {
                  copy {
                    html
                  }
                }
              }
              ... on PrismicArticleDataBody1Quote {
                slice_type
                primary {
                  quote_copy
                  quote_caption
                }
              }
              ... on PrismicArticleDataBody1QuickLinks {
                slice_type
                items {
                  link_title
                  link {
                    url
                    uid
                  }
                  link_icon {
                    alt
                    url
                  }
                }
              }
            }
            body {
              ... on PrismicArticleDataBodySeoPages {
                primary {
                  description
                  og_description
                  og_image {
                    url
                  }
                  og_image_alt
                  og_title
                  twitter_image {
                    url
                  }
                  twitter_image_alt
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ArticlePage
